@import '~antd/dist/antd.css';

html,
body {
  height: 100%;
  width: 100%;
  background-color: #f0f2f5;
}

#root {
  height: 100%;
  width: 100%;
  background-color: #f0f2f5;
}

.site-layout-background {
  transition: margin-left 0.2s ease-out;
}

td.descriptions-right-align {
  text-align: right;
}

.selectable-footer-bar:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.featured-card {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  padding: 12px;
}

.featured-card-title {
  color: #888;
  font-size: 14px;
  width: 100%;
}

.featured-card-money {
  width: 100%;
  font-size: 28px;
}

.featured-card-desc {
  font-size: 11px;
  color: #999;
  float: right;
  margin-top: -8px;
}